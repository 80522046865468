<template>
  <!-- 采购单列表 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button class="itemBtn btnColor" @click="addAndmodify(0)"
          >新增</el-button
        >
        <el-button class="itemBtn btnColor" @click="addAndmodify(1)"
          >修改</el-button
        >
        <el-button class="itemBtn btnColor" @click="fuKuan">付款</el-button>

        <el-button class="itemBtn btnColor" @click="orderTuiKuan"
          >订单退款</el-button
        >
        <el-button class="itemBtn btnColor" @click="cancelBtn">作废</el-button>
        <el-button class="itemBtn btnColor" @click="caigouBtn"
          >添加包裹</el-button
        >
        <el-button class="itemBtn btnColor" @click="myModel"
          >列表设置</el-button
        >
        <el-dropdown @command="handleCommand_dc">
          <el-button class="dropdownBtn">
            导出
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">导出</el-dropdown-item>
            <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
            <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="singleinpbtn wtl_queryBoxNew">
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">{{ MoreConditions }}</div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp wtlTimeBox">
            <div class="singleinpleft wtlTimeLabel">创建时间:</div>
            <div class="wtl_timeStyle">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.startCreateTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
              <div>至</div>
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.endCreateTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div>
          <!-- <div class="singleinp">
            <div class="singleinpleft">开始创建时间:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.startCreateTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">结束创建时间:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.endCreateTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div> -->
          <div class="singleinp">
            <div class="singleinpleft">购买订单:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.buyOrderNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">代购订单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.dgOrderNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 1)"
                v-model="queryData.memberId"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembers"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.memberId"
                  :value="item.memberId + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 2)"
                v-model="queryData.memberName"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembersName"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.nickname"
                  :value="item.nickname + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- <div class="item_left" v-if="hasPermiss('ysgl_yingShouJiFei:query')">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">{{ MoreConditions }}</div>
            </el-tooltip>
          </div>
        </div> -->
      </div>
    </el-form>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        @row-click="rowclick"
        :header-cell-style="{ background: '#f0f0f0' }"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        ></el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        ></el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            :width="item.width"
            sortable
            show-overflow-tooltip
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          ></el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <!-- 自定义表头排序 -->
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 采购 -->
    <div>
      <el-dialog
        title="新建包裹"
        :visible.sync="showCaiGou"
        append-to-body
        width="40%"
        center
        @close="closeCaiGou"
      >
        <div class="addForm">
          <el-form
            label-position="right"
            label-width="100px"
            :model="AddPackData"
            class="addForm"
          >
            <el-form-item class="formItem" label="采购单号:">
              <el-input
                placeholder=""
                v-model="AddPackData.purchaseOrderNum"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item class="formItem" label="会员号:">
              <el-input
                disabled
                placeholder=""
                v-model="AddPackData.memberId"
              ></el-input>
            </el-form-item>
            <el-form-item class="formItem" label="会员名称:">
              <el-input
                disabled
                placeholder=""
                v-model="AddPackData.memberName"
              ></el-input>
            </el-form-item>
            <el-form-item class="formItem" label="归属代理商:">
              <el-input
                disabled
                placeholder=""
                v-model="AddPackData.agentName"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="hasCourNum"
              class="formItem"
              label="已有快递单号:"
            >
              <el-input
                disabled
                placeholder="请输入"
                v-model="AddPackData.courierNumbered"
              ></el-input>
            </el-form-item>
            <el-form-item class="formItem" label="快递单号:">
              <el-input
                placeholder="请输入"
                v-model="AddPackData.courierNumber"
              ></el-input>
            </el-form-item>
            <el-form-item class="formItem formItemSelect" label="快递公司:">
              <el-select
                v-model="AddPackData.expressName"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in expressArr"
                  :key="item.expressNumber"
                  :label="item.expressName"
                  :value="item.expressNumber"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formItem formItemSelect" label="货物品类:">
              <el-select
                v-model="AddPackData.itemCategoryCode"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in huowuleibieList"
                  :key="index"
                  :label="item.showName"
                  :value="item.itemCategoryCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formItem formItemSelect" label="仓库:">
              <el-select
                v-model="AddPackData.storageId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="formTextare" label="备注:">
              <el-input
                type="textarea"
                placeholder="请输入"
                v-model="AddPackData.packageComment"
              ></el-input>
            </el-form-item>
            <el-form-item style="width: 100%">
              <el-checkbox v-model="AddPackData.isPrecious"
                >是否贵重物品</el-checkbox
              >
              <el-checkbox v-model="AddPackData.loanStatus"
                >是否垫付快递费</el-checkbox
              >
            </el-form-item>
          </el-form> 
          <div class="pmBOx">
            <el-table border :data="packageItems" style="width: 100%">
              <el-table-column prop="itemName" label="品名">
                <template slot-scope="scope">
                  <el-input
                    placeholder="请输入"
                    v-model="scope.row.itemName"
                  ></el-input> 
                </template>
              </el-table-column>
              <el-table-column prop="quantity" label="单价"
                ><template slot-scope="scope">
                  <el-input
                    placeholder="请输入"
                    v-model="scope.row.quantity"
                  ></el-input> </template
              ></el-table-column>
              <el-table-column prop="unitPrice" label="数量"
                ><template slot-scope="scope">
                  <el-input
                    placeholder="请输入"
                    v-model="scope.row.unitPrice"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <i
                    v-if="scope.$index == 0"
                    @click="addBtns(scope.row)"
                    style="font-size: 24px"
                    class="el-icon-circle-plus"
                  ></i>
                  <i
                    v-else
                    @click="jianBtns(scope.row, scope.$index)"
                    style="font-size: 24px"
                    class="el-icon-remove"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="btnsColor" @click="createCaigou">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 付款确认 -->
      <el-dialog
        title="付款确认"
        :visible.sync="showFuKuan"
        append-to-body
        width="40%"
        center
        @close="closeFuKuan"
      >
        <div class="addForm">
          <el-form
            label-position="right"
            label-width="100px"
            :model="PayMoneyData"
            style="width: 100%"
          >
            <el-form-item label="采购单号:">
              <el-input
                type="textarea"
                v-model="PayMoneyData.purchaseOrderNums"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="金额合计:">
              <el-input
                disabled
                placeholder=""
                v-model="PayMoneyData.totals"
              ></el-input>
            </el-form-item>
            <el-form-item label="付款账户:">
              <el-select
                v-model="PayMoneyData.financeAssetaccountId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in zijingList"
                  :key="item.accountId"
                  :label="item.accountName"
                  :value="item.accountId + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="付款时间:">
              <el-date-picker
                v-model="PayMoneyData.payTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="图片:">
              <el-upload
                :action="uploadUrl"
                :http-request="uploadSectionImg"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileListImg"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showFuKuan = false">取消</el-button>
          <el-button class="btnsColor" @click="confirmPaymen">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import sorttable from "@/components/sortTemplate.vue";
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";

// import { axios_file } from "@/assets/js/http_axios";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      queryData: {
        agentId: "", //归属代理商id
        buyOrderNumber: "", //购买订单号
        dgOrderNumber: "", //代购订单号
        startCreateTime: "", //起始创建时间
        endCreateTime: "", //结束创建时间
        memberId: "", //会员号
        memberName: "", //会员名称
      },
      isSensitiveList: [
        {
          val: 0,
          name: "非敏感",
        },
        {
          val: 1,
          name: "敏感",
        },
      ],
      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      showCaiGou: false, //采购单
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "采购订单号",
          field: "purchaseOrderNumber",
          width: "150",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "代购订单号",
          field: "dgOrderNumber",
          width: "150",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "购买单号",
          field: "buyOrderNumber",
          width: "150",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "快递单号",
          field: "courierNumber",
          width: "150",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "memberName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "购买备注",
          field: "comment",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "归属代理商",
          field: "agentName",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "资金账户",
          field: "account",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "资金账户名称",
          field: "accountName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "总数量",
          field: "totalCount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "总价",
          field: "totalAmount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },

        {
          name: "付款状态",
          field: "payStatus",
          width: "120",
          sort: 4,
          isShow: false, //不展示
          isTotal: false,
          isSort: false,
        },
        {
          name: "付款状态", //付款状态展示
          field: "payStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "购买来源", //付款状态展示
          field: "platformTypeShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "代购订单状态", //代购订单状态展示值
          field: "orderTypeShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "采购订单状态", //采购订单状态展示值
          field: "orderStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "创建时间",
          field: "createTime",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      packageItems: [
        {
          itemNameCode: "",
          operationType: "ADD", //操作类型: ADD 为 新增; UPDATE 为 修改; DEL 为 删除;
          packageItemId: "",
          itemName: "",
          quantity: "",
          unitPrice: "",
        },
      ],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "PurchaseOrderList", //页面标识

      writeoffStatusList: [], //核销状态
      orderTypeList: [], //代购状态
      orderFormList: [], //订单类型
      unpackingServiceList: [], //拆箱服务
      currentSelectData: [], //选中的数据
      ids: [], //选中的id数据
      AddPackData: {
        courierNumber: "", //快递单号
        expressName: "", //快递公司
        isPrecious: false, //是否贵重物品(0=否 1=是)
        itemCategoryCode: "", //货物品类
        loanStatus: false, //垫付状态:bu_dian_fu:loan_status=不垫付快递费;dian_fu:loan_status=垫付快递费;
        packageComment: "", //包裹备注
        purchaseOrderId: "", //采购单id
        storageId: "", //仓库id
        // 下面是显示不用提交的参数
        purchaseOrderNum: "", //采购单号
        memberId: "", //会员号
        memberName: "", //会员昵称
        agentName: "", //归属代理商
        courierNumbered: "", //已有的快递单号
      },
      hasCourNum: false, //是否已有快递单
      expressArr: [], //快递公司列表
      cangkuList: [], //仓库列表
      huowuleibieList: [], //品类列表
      dialogImageUrl: "",
      dialogVisible: false,
      showFuKuan: false,
      PayMoneyData: {
        purchaseOrderNums: "", //采购单号
        totals: "", //合计
        fileNames: [],
        financeAssetaccountId: "", //资金账号id
        orderIds: "", //订单id数组
        payTime: "", //付款时间
      },
      zijingList: [],
      fileListImg: [],
      ImgNames: [],
      memberList: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.queryData.endCreateTime = tools.getCurrentDate(); //今天的时间;
    this.queryData.startCreateTime = tools.getFlexDate(-10); //10天前的时间

    this.getChaixiangList();
    this.getStatusValList("dg_order_v1.order_type"); //代购状态
    this.getStatusValList("dg_order_v1.order_form"); //订单类型
    this.getVipList(); //快递公司列表
    this.getExpressList(); //快递公司列表
    this.myGetStorageList(); //获取仓库下拉列表
    this.getItemCategoryListNoPage(); //获取货物类别下拉

    this.getData();
  },
  mounted() {
    const that = this;
  },
  activated() {
    // this.getData();
  },
  watch: {},
  methods: {
    huiayu(e, num) {
      if (!e) {
        this.getVipList();
        this.queryData.memberId = "";
        this.queryData.memberName = "";
        return false;
      }
      if (num == 1) {
        this.memberList.forEach((item) => {
          if (item.memberId == e) {
            this.queryData.memberName = item.nickname;
          }
        });
      }
      if (num == 2) {
        this.memberList.forEach((item) => {
          if (item.nickname == e) {
            this.queryData.memberId = item.memberId;
          }
        });
      }
      this.queryBtn_ok();
    },
    // 远程搜索会员
    getMembers(e) {
      if (e.length > 0) {
        this.getVipList(e);
      } else {
        this.getVipList();
      }
    },
    // 远程搜索会员
    getMembersName(e) {
      if (e.length > 0) {
        this.getVipList(e, "name");
      } else {
        this.getVipList();
      }
    },
    getVipList(e, type) {
      let val = e || "";
      Api.getMemberDownList({
        memberId: type ? "" : val,
        nickname: type == "name" ? val : "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
          // this.total = res.data.result.pageCount || 0;
        }
      });
    },
    //点击选中表格行
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    //自定义上传
    uploadSectionImg(param) {
      console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let imgName = res.data.result.fileName;
            let objs = {
              imgName: imgName,
              oldName: fileName,
            };
            this.ImgNames.push(objs);
          }
        })
        .catch((err) => {});
    },
    //删除图片
    handleRemove(file, fileListImg) {
      this.ImgNames.forEach((item, index) => {
        if (item.oldName == file.name || item.imgName == file.name) {
          this.ImgNames.splice(index, 1);
          return;
        }
      });
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 获取资金账户列表
    getAssetaccountList() {
      Api.capitalAccountList().then((res) => {
        this.zijingList = res.data.result || [];
      });
    },
    //快递公司列表
    getExpressList() {
      //  快递公司
      Api.expressChooseList().then((res) => {
        if (res.data.status === "success") {
          this.expressArr = res.data.result;
        } else {
          console.log(`${res.data.message}--快递公司`);
        }
      });
    },
    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
      });
    },
    //获取货物类别下拉
    getItemCategoryListNoPage() {
      Api.getItemCategoryListNoPage().then((res) => {
        this.huowuleibieList = res.data.result || [];
      });
    },
    addBtns(row) {
      console.log(row);
      this.packageItems.push({
        itemNameCode: "",
        operationType: "ADD", //操作类型: ADD 为 新增; UPDATE 为 修改; DEL 为 删除;
        packageItemId: "",
        itemName: "",
        quantity: "",
        unitPrice: "",
      });
    },
    jianBtns(row, index) {
      this.packageItems.splice(index, 1);
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 代购状态
        if (status == "dg_order_v1.order_type") {
          let dataArr = res.data.result || [];
          this.orderTypeList = dataArr;
        }
        // 订单类型
        if (status == "dg_order_v1.order_form") {
          let dataArr = res.data.result || [];
          this.orderFormList = dataArr;
        }
        // // 拆箱服务
        // if (status == "dg_order_v1.unpacking_service") {
        //   let dataArr = res.data.result || [];
        //   this.unpackingServiceList = dataArr;
        // }
      });
    },
    // 获取拆箱服务
    getChaixiangList() {
      Api.getCategoryServiceList({
        directionStatus: "shou:direction_status",
        serviceStatus: "chai_xiang:service_status",
      })
        .then((res) => {
          this.unpackingServiceList = res.data.result || [];
        })
        .catch((err) => {});
    },

    // 费项修改
    editFinance() {
      // documentStatus
      if (this.currentSelectData.length > 1) {
        this.$message.warning("一次只能修改一条数据哦~");
        return;
      } else if (this.currentSelectData.length <= 0) {
        this.$message.warning("您还没有选择数据哦~");
        return;
      }

      // approveStatus: "shen_pi:approve_status"
      let data = this.currentSelectData[0];
      //只有审批中的可以修改费项
      if (data.approveStatus !== "shen_pi:approve_status") {
        this.$message.warning("只有审批中的可以修改费项~");
        return;
      }

      //仓库打包单据
      if (data.documentStatus == "bill_storepack_v1:document_status") {
        console.log("仓库打包单据");
        this.$router.push({
          path: "/Finance/modifyFee",
          query: {
            pageType: "newpage",
            documentStatus: data.documentStatus,
            financeInId: data.financeInId,
          },
        });
      } else {
        this.$message.warning("只支持修改仓库打包单哦!");
      }

      // //申请验货单
      // if (data.documentStatus == "bill_inspection_v1:document_status") {
      //   console.log("申请验货单");
      //   Api.getFinanceInChargingInspectionDetail(param).then((res) => {});
      // }
      // //其他单据
      // if (data.documentStatus == "finance_documentstatus_v1:document_status") {
      //   console.log("其他单据");
      // }
      // //拒收单据
      // if (data.documentStatus == "bill_rejection_v1:document_status") {
      //   console.log("拒收单据");

      //   Api.getFinanceInChargingRejectionDetail(param).then((res) => {});
      // }
      // //充值转账单据
      // if (data.documentStatus == "bill_transfer_v1:document_status") {
      //   console.log("充值转账单据");
      // }

      // //包裹异常单据
      // if (data.documentStatus == "bill_package_exception_v1:document_status") {
      //   console.log("包裹异常单据");
      // }
    },
    // 新增修改
    addAndmodify(a) {
      let type = a; //0=新增,1=修改
      // 修改
      if (type == 1) {
        let cDatas = this.currentSelectData || [];
        if (cDatas.length == 0) {
          this.$message.warning("请选择选择一条采购单");
          return false;
        } else if (cDatas.length > 1) {
          this.$message.warning("一次只能修改一条采购单");
          return false;
        }
        this.$router.push(
          "/pages/IndentOrder/EditPurchaseOrder?pageType=edit&purchaseOrderId=" +
            cDatas[0].purchaseOrderId
        );
      } else {
        // 新增
        this.$router.push("/pages/IndentOrder/addPurchaseOrder?pageType=add");
      }
    },
    // 付款
    fuKuan() {
      let cDatas = this.currentSelectData;
      if (cDatas.length == 0) {
        this.$message.warning("请选择一条以上采购单进行付款");
        return false;
      }
      let ids = [];
      let purchaseOrderNumbers = "";
      let totalAmounts = 0;
      for (var i = 0; i < cDatas.length; i++) {
        let item = cDatas[i];
        ids.push(item.purchaseOrderId);
        purchaseOrderNumbers += item.purchaseOrderNumber + ",";
        totalAmounts = this.Tools.add(totalAmounts, item.totalAmount);
        if (item.payStatus !== "wei_fu_kuan:pay_status") {
          this.$message.warning("所选采购单有已付款的单,请重新选择");
          return false;
        }
      }

      this.showFuKuan = true;
      this.PayMoneyData.purchaseOrderNums = purchaseOrderNumbers;
      this.PayMoneyData.totals = this.Tools.mathToFixed(totalAmounts, 2);
      this.PayMoneyData.orderIds = ids;
      this.getAssetaccountList(); //资金账户列表
    },
    // 付款确认
    confirmPaymen() {
      let fnames = [];
      this.ImgNames.forEach((item) => {
        fnames.push(item.imgName);
      });

      let param = {
        fileNames: fnames,
        financeAssetaccountId: this.PayMoneyData.financeAssetaccountId,
        orderIds: this.PayMoneyData.orderIds,
        payTime: this.PayMoneyData.payTime,
      };
      param.sign = this.Tools.getSign(param);
      Api.bmg_payPurchaseOrder(param).then((res) => {
        this.$message.success(res.data.message || "付款成功");
        this.showFuKuan = false;
        this.getData();
      });
    },
    // 退款
    orderTuiKuan() {
      console.log(this.currentSelectData);
      if (this.currentSelectData.length < 1) {
        this.$message.warning("请选择一条以上的数据");
        return false;
      }

      let ids = [];
      for (var i = 0; i < this.currentSelectData.length; i++) {
        let item = this.currentSelectData[i];
        if (item.payStatus != "yi_fu_kuan:pay_status") {
          this.$message.warning("只有已付款状态的订单可以退款噢");
          return false;
        } else {
          ids.push(item.purchaseOrderId);
        }
      }
      let param = {
        orderIds: ids,
      };
      param.sign = tools.getSign(param);
      this.$confirm("所选采购单是否确认退款返核销?", "提示", {
        type: "warning",
      })
        .then((res) => {
          Api.bmg_payRefundPurchaseOrder(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },
    // 取消
    cancelBtn() {
      if (this.currentSelectData.length < 1) {
        this.$message.warning("请先选择一条以上的数据");
        return false;
      }

      let ids = [];
      for (var i = 0; i < this.currentSelectData.length; i++) {
        let item = this.currentSelectData[i];
        // if (item.orderType != "dai_fu_kuan:order_type") {
        //   this.$message.warning("只有待付款状态的订单可以取消噢");
        //   return false;
        // } else {
        //   ids.push(item.dgOrderId);
        //
        // }
        ids.push(item.purchaseOrderId);
      }
      let param = {
        orderIds: ids,
      };
      param.sign = tools.getSign(param);
      this.$confirm("是否作废所选采购单?", "提示", { type: "warning" })
        .then((res) => {
          Api.bmg_deletePurchaseOrder(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },

    // 添加包裹
    caigouBtn() {
      if (this.currentSelectData.length == 0) {
        this.$message.warning("您还没选择数据~");
        return false;
      }
      if (this.currentSelectData.length > 1) {
        this.$message.warning("一次只能选一条采购单噢~");
        return false;
      }
      let currentData = this.currentSelectData[0];
      console.log(currentData);
      if (currentData.courierNumber) {
        this.$confirm("所选代购单已存在包裹,是否继续添加包裹?", "提示")
          .then((res) => {
            this.hasCourNum = true;
            this.AddPackData.courierNumbered = currentData.courierNumber;
            // this.AddPackData.courierNumber = currentData.courierNumber;
            this.AddPackData.purchaseOrderNum = currentData.purchaseOrderNumber;
            this.AddPackData.purchaseOrderId = currentData.purchaseOrderId;
            this.AddPackData.memberId = currentData.memberId;
            this.AddPackData.memberName = currentData.memberName;
            this.AddPackData.agentName = currentData.agentName;
            this.showCaiGou = true;
          })
          .catch(() => {});
      } else {
        this.hasCourNum = false;
        this.AddPackData.purchaseOrderNum = currentData.purchaseOrderNumber;
        this.AddPackData.purchaseOrderId = currentData.purchaseOrderId;
        this.AddPackData.memberId = currentData.memberId;
        this.AddPackData.memberName = currentData.memberName;
        this.AddPackData.agentName = currentData.agentName;
        this.showCaiGou = true;
      }
    },
    // 新建采购单
    createCaigou() {
      let newPItems = JSON.parse(JSON.stringify(this.packageItems));
      for (var i = 0; i < newPItems.length; i++) {
        if (!newPItems[i].itemName) {
          newPItems.splice(i, 1);
          if (i > 1) {
            i--;
          }
        }
      }
      let parm = {
        courierNumber: this.AddPackData.courierNumber,
        expressName: this.AddPackData.expressName,
        isPrecious: this.AddPackData.isPrecious ? "1" : "0", //是否贵重物品(0=否 1=是)
        itemCategoryCode: this.AddPackData.itemCategoryCode,
        loanStatus: this.AddPackData.loanStatus
          ? "dian_fu:loan_status"
          : "bu_dian_fu:loan_status", //垫付状态:bu_dian_fu:loan_status=不垫付快递费;dian_fu:loan_status=垫付快递费;
        packageComment: this.AddPackData.packageComment,
        purchaseOrderId: this.AddPackData.purchaseOrderId,
        storageId: this.AddPackData.storageId,
        packageItems: newPItems,
      };
      parm.sign = this.Tools.getSign(parm);
      Api.bmg_addPurchaseOrderPackage(parm).then((res) => {
        this.$message.success(res.data.message || "新建成功");
        this.showCaiGou = false;
        this.getData();
      });
    },

    // 关闭付款确认弹窗事件
    closeFuKuan() {
      this.ImgNames = [];
      this.PayMoneyData = {
        purchaseOrderNums: "", //采购单号
        totals: "", //合计
        fileNames: [],
        financeAssetaccountId: "", //资金账号id
        orderIds: "", //订单id数组
        payTime: "", //付款时间
      };
    },
    // 关闭新建采购单弹出
    closeCaiGou() {
      this.AddPackData = {
        courierNumber: "", //快递单号
        expressName: "", //快递公司
        isPrecious: false, //是否贵重物品(0=否 1=是)
        itemCategoryCode: "", //货物品类
        loanStatus: false, //垫付状态:bu_dian_fu:loan_status=不垫付快递费;dian_fu:loan_status=垫付快递费;
        packageComment: "", //包裹备注
        purchaseOrderId: "", //采购单id
        storageId: "", //仓库id
        // 下面是显示不用提交的参数
        purchaseOrderNum: "", //采购单号
        memberId: "", //会员号
        memberName: "", //会员昵称
        agentName: "", //归属代理商
        courierNumbered: "", //已有的快递单号
      };
      this.hasCourNum = false;
      this.packageItems = [
        {
          itemNameCode: "",
          operationType: "ADD", //操作类型: ADD 为 新增; UPDATE 为 修改; DEL 为 删除;
          packageItemId: "",
          itemName: "",
          quantity: "",
          unitPrice: "",
        },
      ];
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 10) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let that = this;

      //   创建时间
      let endCreateTime = "";
      let startCreateTime = "";
      if (that.queryData.endCreateTime) {
        endCreateTime = that.queryData.endCreateTime + " " + "23:59:59";
      }
      if (that.queryData.startCreateTime) {
        startCreateTime = that.queryData.startCreateTime + " " + "00:00:00";
      }

      let formData = {
        agentId: "", //归属代理商id
        buyOrderNumber: that.queryData.buyOrderNumber, //
        dgOrderNumber: that.queryData.dgOrderNumber, //代购订单号
        startCreateTime: startCreateTime, //起始创建时间
        endCreateTime: endCreateTime, //结束创建时间
        memberId: that.queryData.memberId, //会员号
        memberName: that.queryData.memberName, //会员名称
        pageStart: that.pageNum, //页码
        pageTotal: that.size, //每页条数
      };

      Api.bmg_getPurchaseOrderList(formData).then((res) => {
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          that.total = res.data.result.pageCount || 0;

          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        agentId: "", //归属代理商id
        buyOrderNumber: "", //购买订单号
        dgOrderNumber: "", //代购订单号
        startCreateTime: "", //起始创建时间
        endCreateTime: "", //结束创建时间
        memberId: "", //会员号
        memberName: "", //会员名称
      };
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      ////console.log(e);
      this.currentSelectData = e || [];
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  // return prev + curr;
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.btnsColor {
  background-color: #f0a261;
  color: #fff;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.HeadInputbox .item_right .singleinp {
  ::v-deep {
    .el-date-editor.el-input {
      width: 100%;
    }
  }
}

.addForm {
  display: flex;
  flex-wrap: wrap;
  /deep/ .el-input__inner,
  .el-form-item__label {
    line-height: 30px;
    height: 30px;
  }
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }

  .formItem {
    width: 45%;
    margin-right: 2%;
  }
  .formTextare {
    min-width: 400px;
    flex: 1;
  }
}
.pmBOx {
  width: 90%;
  margin: 0 auto;
  /deep/ .el-input__inner {
    border: none;
  }
}
</style>
